.player {
  box-sizing: border-box;
  overflow: hidden;
  padding: 3em;
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  border: 1px solid var(--border-player);
  border-radius: 9px;
  /* border-right: 0; */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: -10px 10px 30px -20px rgb(0 0 0 / 80%);
}

.btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.player button {
  font-size: 1.5em;
  color: var(--border-player);
  background-color: var(--background-color);
  border: none;
  outline: none;
  cursor: pointer;
}

.player:active button {
  border: none;
}

.player img {
  border-radius: 5px;
  margin: 1em;
  box-shadow: -10px 10px 30px -20px rgb(0 0 0 / 80%);
}

.text-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
}

.song-times {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 21%;
}

.song-times span {
  display: flex;
}

.song-text-player {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.songName-player {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
}

.waveform {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Toastify__toast-body {
  color: var(--color-body) !important;
  background-color: var(--cards-color) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Toastify__toast--default {
  color: var(--color-body) !important;
  background-color: var(--cards-color) !important;
  border-left: 5px solid var(--border-toast);
  border-radius: 3px;
}

.Toastify__toast-container {
  z-index: 9999;
  position: fixed;
  padding: 4px;
  width: 240px;
  box-sizing: border-box;
  color: #fff;
}

.isShuffled {
  fill: var(--border-toast);
}

@media screen and (max-width: 500px) {
  .player {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid var(--border-player);
    border-radius: 9px;
  }
  .player img {
    width: 70%;
    border-radius: 5px;
  }
  .text-panel {
    font-size: 12px;
    width: 100%;
    margin-bottom: 25px;
  }
}
