
.nav-bar {
  text-transform: lowercase;
  padding-bottom: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-left {
  display: flex;
  justify-content: space-between;
}

.nav-left a {
  text-decoration: none;
  color: var(--font-color);
  margin-right: 1em;
  transition: all 200ms ease-in-out;
}

.nav-bar a.active {
  color: #edabb5;
}

.nav-bar a:last-child {
  margin-right: 0;
}

