html {
  background-color: var(--background-color);
  height: 100%;
}

*,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  letter-spacing: 0.25px;
  color: var(--color-body);
  transition: color 0.1s ease-in-out;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#noisyback {
  position: fixed;
  top: -50vh;
  left: -50vw;
  width: 200vw;
  height: 200vh;
  background-image: url("images/noise-transparent.png");
  background-size: 30rem;
  background-color: var(--background-color);
  transition: background-color 0.1s;
  opacity: var(--bg-opacity);
  z-index: -1;
  will-change: transform;
  animation-name: noise;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-delay: 0s;
  animation-timing-function: steps(10);
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
}

@keyframes noise {
  0% {
    transform: translate(0);
  }
  10% {
    transform: translate(-5%, -5%);
  }
  20% {
    transform: translate(-10%, 5%);
  }
  30% {
    transform: translate(5%, -10%);
  }
  40% {
    transform: translate(-5%, 15%);
  }
  50% {
    transform: translate(-10%, 5%);
  }
  60% {
    transform: translate(15%);
  }
  70% {
    transform: translateY(10%);
  }
  80% {
    transform: translate(-15%);
  }
  90% {
    transform: translate(10%, 5%);
  }
  100% {
    transform: translate(5%);
  }
}

h1 {
  font-size: 40px;
}

.homePage {
  padding: 64px 14px;
  max-width: 740px;
  margin: auto;
}

p {
  padding-top: 16px;
  padding-left: 0;
}

@media (max-width: 480px) {
  h1 {
    font-size: 30px;
  }
  p {
    font-size: 14px;
  }
}
