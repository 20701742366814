.grid-container {
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: start;
  margin-top: 32px;
  max-width: 100%;
}

.photo-container {
  display: flex;
  justify-content: center;
  position: relative;
  /* cursor: zoom-in; */
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 4px;
}

img.photo-itself {
  width: 100%;
}

.hover-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.34) 0,
    rgba(0, 0, 0, 0.338) 3.5%,
    rgba(0, 0, 0, 0.324) 7%,
    rgba(0, 0, 0, 0.306) 10.35%,
    rgba(0, 0, 0, 0.285) 13.85%,
    rgba(0, 0, 0, 0.262) 17.35%,
    rgba(0, 0, 0, 0.237) 20.85%,
    rgba(0, 0, 0, 0.213) 24.35%,
    rgba(0, 0, 0, 0.188) 27.85%,
    rgba(0, 0, 0, 0.165) 31.35%,
    rgba(0, 0, 0, 0.144) 34.85%,
    rgba(0, 0, 0, 0.126) 38.35%,
    rgba(0, 0, 0, 0.112) 41.85%,
    rgba(0, 0, 0, 0.103) 45.35%,
    rgba(0, 0, 0, 0.1) 48.85%,
    rgba(0, 0, 0, 0.103) 52.35%,
    rgba(0, 0, 0, 0.112) 55.85%,
    rgba(0, 0, 0, 0.126) 59.35%,
    rgba(0, 0, 0, 0.144) 62.85%,
    rgba(0, 0, 0, 0.165) 66.35%,
    rgba(0, 0, 0, 0.188) 69.85%,
    rgba(0, 0, 0, 0.213) 73.35%,
    rgba(0, 0, 0, 0.237) 76.85%,
    rgba(0, 0, 0, 0.262) 80.35%,
    rgba(0, 0, 0, 0.285) 83.85%,
    rgba(0, 0, 0, 0.306) 87.35%,
    rgba(0, 0, 0, 0.324) 90.85%,
    rgba(0, 0, 0, 0.338) 94.35%,
    rgba(0, 0, 0, 0.347) 97.85%,
    rgba(0, 0, 0, 0.35)
  );
}

.image-stats {
  display: none;
  justify-content: space-between;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-bottom: 0.5em;
  z-index: 2;
  color: white;
  font-size: 15px;
}

.image-stats svg {
  width: 15px;
  margin-right: 0.25em;
  margin-top: 0.3em;
  stroke: white;
}

.photo-container:hover .hover-shadow,
.photo-container:hover .image-stats {
  display: flex;
}
/* .photo-container:hover .image-stats  */

.views,
.downloads {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.5em;
}

.views {
  padding-right: 0.25em;
}

.image-numbers {
  color: white;
}

.links {
  text-decoration: none;
  cursor: pointer;
}

.links:hover {
  text-decoration: underline;
  font-style: italic;
}

@media screen and (max-width: 480px) {
  h3 {
    font-size: 14px;
  }
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
