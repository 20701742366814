/* ==================================== */
/*        Glowy Text Component          */
/* ==================================== */
.page-link {
  position: relative;
}

.page-link,
.glowing-text {
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.glowing-text {
  position: absolute;
  left: 0px;
  z-index: 0;
  filter: blur(10px) opacity(0);
  transition: 0.15s ease;
}

.page-link:hover .glowing-text {
  filter: blur(10px) opacity(0.7);
}

.page-link.photographer,
.page-link.photographer .glowing-text {
  background-color: #f72585;
  background-image: linear-gradient(to right, #f7af9d, #f72585);
  cursor: pointer;
}

.page-link.traveller,
.page-link.traveller .glowing-text {
  background-color: #64f38c;
  background-image: linear-gradient(to right, #f79d00, #64f38c);
  cursor: pointer;
}

.page-link.developer,
.page-link.developer .glowing-text {
  background-color: #00d2ff;
  background-image: linear-gradient(to right, #00d2ff, #4361ee);
  cursor: pointer;
}

/* ==================================== */
