h1 {
  background: #fc466b;
  background-image: radial-gradient(
    circle,
    #0066ff,
    #8558f4,
    #bb45e1,
    #e22bca,
    #fc466b
  );

  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* animation: rainbow-text-simple-animation-rev 2s ease-in-out both;
  animation-direction: alternate; */
}

.gradient-text:hover {
  animation: rainbow-text-simple-animation 2s ease-in-out both;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes rainbow-text-simple-animation {
  0% {
    background-size: 100%;
  }
  80% {
    background-size: 650%;
  }
  100% {
    background-size: 650%;
  }
  80% {
    background-size: 650%;
  }
  0% {
    background-size: 100%;
  }
}

.description_container {
  margin-top: 16px;
}

.desc_location {
  display: flex;
  align-items: center;
  padding-top: 0;
}

.location {
  font-style: italic;
}

.wave {
  height: 20px;
  width: 21px;
  position: relative;
  overflow: hidden;
  margin-left: 8px;
  margin-top: 9px;
  border-radius: 50%;
}

.wave-wrapper {
  display: inline-flex;
}

.wave::before,
.wave::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -12px;
  right: 0;
  background-repeat: repeat;
  height: 14px;
  width: 48px;
  background-size: 12px 12px;
  animation: wave-animation 3s linear infinite;
  transition: bottom 0.15s ease-out 0.35s;
}

.wave:before {
  bottom: 0;
}
.wave:after {
  bottom: -4px;
}

.wave::before {
  background-image: radial-gradient(
    circle at 6px -3px,
    transparent 6px,
    #a2d2ff 6px
  );
  animation-duration: 2s;
  opacity: 0.5;
}
.wave::after {
  background-image: radial-gradient(
    circle at 6px -3px,
    transparent 6px,
    #49b6ff 6px
  );
  animation-duration: 3s;
}

@keyframes wave-animation {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
