.nav-right .toggleDark {
  cursor: pointer;
  user-select: none;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.icon-mode {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 72px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.75s ease;
}

.icon-mode.light {
  transform: translateY(14px);
}

.icon-mode.dark {
  transform: translateY(-14px);
}
