.stats-container {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  margin-bottom: 1.7rem;
}

.total-views,
.total-downloads {
  display: flex;
  padding: 20px 25px 20px;
  width: 100%;
  border: 1px solid var(--cards-color);
  border-radius: 6px;
  background-color: var(--cards-color);
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 1em;
  height: 100%;
}

.icon path,
.icon circle {
  stroke-width: 1px;
}
.total-views .icon path,
.icon circle {
  stroke: var(--icon-view-color);
}
.total-downloads .icon path {
  stroke: var(--icon-dwnlds-color);
}

.text-and-count {
  display: flex;
  flex-direction: column;
}

.total-views {
  margin-right: 1em;
}

.text-card {
  margin-bottom: 0.25em;
  font-size: 0.9rem;
}

.numbers-card {
  font-size: 1.125rem;
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .total-views,
  .total-downloads {
    padding: 15px 10px 15px;
  }
  .text-card {
    display: none;
  }
  .numbers-card {
    font-size: 14px;
    font-weight: 500;
    padding-top: 3px;
  }
  .icon {
    margin-right: 10px;
    height: 100%;
  }
}
