.big-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  font-size: 13px;
}

.playlist {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  position: relative;
  height: 100%;
  width: 60%;
  background-color: var(--background-color);
  border-style: solid;
  border-width: 1px;
  border-color: var(--border-player);
  border-radius: 9px;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.playlist img {
  width: 48px;
  border-radius: 30px;
  margin-right: 1em;
}

.blur-back {
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-position: top;
  filter: blur(12px);
  opacity: var(--bg-opacity-playlist);
}

.each-song {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 0.5em;
  width: 100%;
  cursor: pointer;
  z-index: 1;
}

.each-song:not(:last-child) {
  border-style: none none solid none;
  border-width: 0.5px;
  border-color: #999999;
}

.each-song:hover {
  background-color: var(--btn-highlight);
}

.each-song:focus {
  background-color: var(--btn-highlight);
}

.song-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1em;
}

.songName {
  margin-right: 8px;
}

.artistName {
  color: #999999;
}

.waveform {
  width: 100%;
  height: 80px;
}

@media screen and (max-width: 500px) {
  .big-wrap {
    flex-direction: column;
    height: 100%;
    font-size: 13px;
  }
  .playlist {
    width: 100%;
    border: 1px solid var(--border-player);
    border-radius: 9px;
  }
}
