.footer {
  padding-top: 64px;
  color: var(--color-body);
}

.footer a {
  margin-right: 1.5em;
}

.footer a:last-child {
  margin-right: 0;
}

.footer div:first-child {
  padding-bottom: 16px;
}
.footer div:first-child a {
  transition: font-style 200ms ease;
}
.footer div:first-child > a:hover {
  font-style: italic;
}

.twitter,
.github,
.instagram {
  height: 15px;
  width: 15px;
}

.footer .twitter path,
.footer .github path,
.footer .instagram path {
  fill: var(--socials-color);
}
