:root {
  --html-color: #f8edeb;
  --background-color: #f8edeb;
  --color-body: black;
  --bg-opacity: 0.9;
  --socials-color: black;
  --cards-color: #f7eae7;
  --icon-view-color: black;
  --icon-dwnlds-color: black;
  --border-player: transparent;
  --btn-highlight: #cdcdcd27;
  --border-toast: #fd5d93;
  --bg-opacity-playlist: 0.12;
}

.dark {
  --html-color: black;
  --background-color: black;
  --color-body: white;
  --bg-opacity: 1;
  --socials-color: white;
  --cards-color: #121317;
  --icon-view-color: #fd5d93;
  --icon-dwnlds-color: #00f2c3;
  --border-player: #00f2c3;
  --btn-highlight: #cecece17;
  --border-toast: #00f2c3;
  --bg-opacity-playlist: 0.22;
}
